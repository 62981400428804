import axios from "axios";
import Vue from "vue";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = window.sessionStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint, body) {
    return axiosInstance.delete(endpoint, body);
  },
  login(body) {
    return axiosInstance.post("/login", body);
  },
  validate() {
    const token = sessionStorage.token;
    return axiosInstance.get(
      "/login/validateToken?authToken=" + token.replace("Bearer ", "")
    );
  },
};

export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}

Vue.prototype.$http = api;

axiosInstance.interceptors.response.use((response) => {
  if (response.status == 401) {
    this.$store.dispatch("logout");
    this.$router.push({ name: "login" });
  }
  if (response.status == 429) {
    this.$store.dispatch("logout");
    this.$router.push({ name: "login" });
  }
  return response;
});
