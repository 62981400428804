import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dasboard",
    name: "Dashboard",
    props: true,
    meta: {
      login: true,
      admin: false
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/admin/Index.vue"),
  },
  {
    path: "/",
    name: "Home",
    meta: {
      login: true,
      admin: false
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    children: [
      {
        path: "",
        name: "Inscricoes",
        meta: {
          login: true,
          admin: false
        },
        component: () =>
          import(
            /* webpackChunkName: "insc" */ "../views/Dashboard/Inscricoes.vue"
          ),
      },
      {
        path: "/clientes",
        name: "Clientes",
        meta: {
          login: true,
          admin: false
        },
        component: () =>
          import(
            /* webpackChunkName: "client" */ "../views/Dashboard/Clientes.vue"
          ),
      },
      {
        path: "/pedidos",
        name: "Pedidos",
        meta: {
          login: true,
          admin: false
        },
        component: () =>
          import(
            /* webpackChunkName: "pedido" */ "../views/Dashboard/Pedidos.vue"
          ),
      },
      {
        path: "/configuracoes",
        name: "Configs",
        meta: {
          login: true,
          admin: false
        },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/Dashboard/Configs.vue"
          ),
      },
      {
        path: "/administradores",
        name: "Admins",
        meta: {
          login: true,
          admin: false
        },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/Dashboard/Administradores.vue"
          ),
      },
    ]
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404/404.vue"),
  },
  {
    path: "/login",
    name: "Login",
    props: true,
    login: false,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Index.vue"),
  },
  {
    path: "/dados-cadastrais",
    name: "Dados",
    meta: {
      login: true,
      admin: false
    },
    component: () =>
      import(
        /* webpackChunkName: "dados" */ "../views/Dashboard/DadosCadastrais.vue"
      ),
  },



];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.sessionStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
