<template>
  <section class="main">
    <transition mode="out-in" name="slide-fade">
      <router-view />
    </transition>
       <Toast position="top-right" />
  </section>
</template>

<script>
import Toast from "primevue/toast";
export default {
  name: "App",
  components: {
    Toast
  },
  created() {
    if (sessionStorage.token) {
      this.$store.commit("UPDATE_USER", JSON.parse(sessionStorage.user));
    } else {
      this.$store.dispatch("logout");
    }
  },
};
</script>

<style lang="scss">
</style>
