import Vue from "vue";
import Vuex from "vuex";
import { api } from "../services";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    logged: false,
    user: {},
    event: {},
    modalCadastro: true
  },
  mutations: {
    UPDATE_LOGIN(state, payload) {
      state.logged = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = Object.assign(state.user, payload);
    },
    UPDATE_EVENT(state, payload) {
      state.event = Object.assign(state.event, payload);
    },
    UPDATE_MODAL(state, payload) {
      state.modal = payload
  },
  },
  actions: {
    modal(context, payload) {
      context.commit("UPDATE_MODAL", payload);

    },
    login(context, payload) {
      context.commit("UPDATE_LOGIN", payload);
      window.sessionStorage.logged = payload;
    },
    logout(context) {
      context.commit("UPDATE_LOGIN", false);
      window.sessionStorage.clear();
    },
    me(context) {
      api
        .get("/me")
        .then((response) => {
          context.commit("UPDATE_USER", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    event(context, payload) {
      axios
        .get(
          `${process.env.VUE_APP_WORDPRESS_URL}/wp-json/produtos/single/?id=${payload}`
        )
        .then((response) => {
          context.commit("UPDATE_EVENT", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateUser(context) {
      api
        .put("/atualiza-dados", context.state.user)
        .then((response) => {
          context.commit("UPDATE_USER", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  modules: {},
  getters: {
    modal: (state) => {
      return state.modalCadastro;
    },
    getLoginStatus: (state) => {
      return state.logged;
    },
    user: (state) => {
      return state.user;
    },
    event: (state) => {
      return state.event;
    },
  },
});
