import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/mdc-light-indigo/theme.css";
import "@/assets/scss/main.scss";
import VueFeather from "vue-feather";
import InputText from "primevue/inputtext";
import VueContentPlaceholders from "vue-content-placeholders";
import titleMixin from "./mixins/titleMixin";
import VueTheMask from "vue-the-mask";
import ToastService from "primevue/toastservice";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

Vue.use(ToastService);
const toastService = {
  toast: (severity, sumary, message) => {
      this.$toast.add({
        severity: severity,
        summary: sumary,
        detail: message,
        life: 3000,
      });
    },
}

Vue.prototype.$toastService = toastService;
Vue.use(VueTheMask);
Vue.mixin(titleMixin);
Vue.use(VueContentPlaceholders);
Vue.component("InputText", InputText);
Vue.component("feather", VueFeather);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
